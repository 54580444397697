import "./App.css";
import { useState } from "react";
import Header from "./components/Header/Header";
import Section6 from "./components/Section6/Section6";
import Home from "./components/Pages/Home";
import TOS from "./components/Others/TOS";
import Privacy from "./components/Others/Privacy";
import RequestPopup from "./components/Modal/RequestPopup";
import { Routes, Route } from "react-router-dom";
function App() {
  let [modalStatus, changeModalStatus] = useState({
    data: null,
    status: false,
  });
  return (
    <div className="App">
      <RequestPopup
        changeModalStatus={changeModalStatus}
        modalStatus={modalStatus}
      />
      <Header changeModalStatus={changeModalStatus} />
      <Routes>
        <Route path="/terms-of-service" element={<TOS />} />
        <Route path="/privacy-policy" element={<Privacy />} />
        <Route
          path="/"
          element={<Home changeModalStatus={changeModalStatus} />}
        />
      </Routes>
      <Section6 />
    </div>
  );
}

export default App;
