import React, { useEffect, useState } from "react";
import Styles from "./RequestPopup.module.css";
import closeIcon from "../../assets/closeIcon.svg";
import stroke from "../../assets/stroke.svg";
import formSuccessIcon from "../../assets/formSuccess.svg";
import classnames from "classnames";
import $ from "jquery";
import axios from "axios";
function RequestPopup(props) {
  let [modalState, changeModalState] = useState("form");
  let [errorData, changeErrorData] = useState(null);
  let [selectedPlan, changeSelectedPlan] = useState(null);
  function closeModal() {
    changeModalState("form");
    props.changeModalStatus((oldState) => {
      let temp = { ...oldState };
      temp.status = false;
      temp.data = null;
      return temp;
    });
  }

  function handlePlan() {
    changeSelectedPlan(getPlan());
  }

  useEffect(() => {
    console.log("bc");
    changeSelectedPlan(getPlan());
  }, [props.modalStatus.status]);
  useEffect(() => {
    function handleClick(e) {
      let classnamesStr = e.target.getAttribute("class");
      if (classnamesStr && classnamesStr.search("containerWrapper") > -1) {
        closeModal();
      }
    }
    document.addEventListener("click", handleClick);
    return () => {
      document.removeEventListener("click", handleClick);
    };
  });

  function showFieldError(id) {
    $($(`#${id}`).siblings()[1]).css("display", "block");

    setTimeout(() => {
      $($(`#${id}`).siblings()[1]).css("display", "none");
    }, 3000);
  }

  function showError(error) {
    changeErrorData(error);
    setTimeout(() => {
      changeErrorData(null);
    }, 4000);
  }

  function getPlan() {
    let plan = document.getElementById("plan").value;
    return plan;
  }
  function handleSubmit() {
    let companyName = document.getElementById("companyName").value;
    let name = document.getElementById("name").value;
    let email = document.getElementById("customerEmail").value;
    let country = document.getElementById("country").value;
    let annualRevenue = document.getElementById("annualRevenue").value;

    let allComplete = true;
    if (!companyName) {
      showFieldError("companyName");
      allComplete = false;
    }
    if (!name) {
      showFieldError("name");
      allComplete = false;
    }

    if (!email) {
      showFieldError("customerEmail");
      allComplete = false;
    }
    if (!country) {
      showFieldError("country");
      allComplete = false;
    }
    if (!annualRevenue) {
      showFieldError("annualRevenue");
      allComplete = false;
    }

    if (allComplete) {
      let formData = new FormData();
      formData.append("companyName", companyName);
      formData.append("name", companyName);
      formData.append("email", email);
      formData.append("country", country);
      formData.append("annualRevenue", annualRevenue);

      axios
        .post("https://kewlhosting.net/appointment.php", formData)
        .then((data) => {
          let status = data.data.status;
          if (status == "ok") {
            changeModalState("done");
          } else {
            showError(data.data.message);
          }
        })
        .catch((err) => {
          showError("Some error occured, Please try again");
        });
    }
  }

  function handleFormSubmit(e) {
    e.preventDefault();
    handlePlan();
    let companyName = document.getElementById("companyName").value;
    let name = document.getElementById("name").value;
    let email = document.getElementById("customerEmail").value;
    let country = document.getElementById("country").value;
    let annualRevenue = document.getElementById("annualRevenue").value;

    let allComplete = true;
    if (!companyName) {
      showFieldError("companyName");
      allComplete = false;
    }
    if (!name) {
      showFieldError("name");
      allComplete = false;
    }

    if (!email) {
      showFieldError("customerEmail");
      allComplete = false;
    }

    if (!country) {
      showFieldError("country");
      allComplete = false;
    }
    if (!annualRevenue) {
      showFieldError("annualRevenue");
      allComplete = false;
    }
    if (!selectedPlan) {
      allComplete = false;
    }
    if (!validateEmail(email)) {
      showError("Invalid Email");
      allComplete = false;
    }
    if (allComplete) {
      changeSelectedPlan(null);
      document.getElementById("dataForm").submit();
    }
  }
  function getSelected() {
    if (props.modalStatus.data && props.modalStatus.data.plan) {
      return props.modalStatus.data.plan;
    } else {
      return null;
    }
  }

  const validateEmail = (email) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };
  return (
    <div
      className={classnames(
        Styles.containerWrapper,
        props.modalStatus.status ? Styles.containerWrapperActive : ""
      )}
    >
      <div className={Styles.container}>
        <img src={stroke} className={Styles.stroke} alt="stroke" />
        <img
          src={closeIcon}
          className={Styles.closeIcon}
          onClick={() => {
            closeModal();
          }}
          alt="img"
        />

        {modalState == "form" ? (
          <div className={Styles.demoFormWrapper}>
            <p className={Styles.heading}>Checkout</p>
            {errorData ? <p className={Styles.errorData}>{errorData}</p> : ""}
            <div className={Styles.demoForm}>
              <div className={Styles.demoFormFieldWrapper}>
                <p className={Styles.formLabel}>Company</p>
                <input
                  type="text"
                  className={Styles.formInput}
                  id="companyName"
                />
                <p className={Styles.requiredField}>*Required</p>
              </div>
              <div className={Styles.demoFormFieldWrapper}>
                <p className={Styles.formLabel}>Full name</p>
                <input type="text" className={Styles.formInput} id="name" />
                <p className={Styles.requiredField}>*Required</p>
              </div>
              <div className={Styles.demoFormFieldWrapper}>
                <p className={Styles.formLabel}>Email address</p>
                <input
                  type="text"
                  className={Styles.formInput}
                  id="customerEmail"
                  defaultValue={
                    props.modalStatus.data && props.modalStatus.data.userEmail
                      ? props.modalStatus.data.userEmail
                      : ""
                  }
                />
                <p className={Styles.requiredField}>*Required</p>
              </div>
              {(props.modalStatus.data && props.modalStatus.data.plan) ||
              true ? (
                <div className={Styles.demoFormFieldWrapper}>
                  <p className={Styles.formLabel}>Plan</p>
                  <select
                    className={Styles.formSelect}
                    id="plan"
                    onChange={handlePlan}
                  >
                    <option
                      value="Basic - $7.5"
                      selected={
                        getSelected() && getSelected() == "Basic" ? true : false
                      }
                    >
                      Basic - $7.5
                    </option>
                    <option
                      value="Premium - $19.99"
                      selected={
                        getSelected() && getSelected() == "Premium"
                          ? true
                          : false
                      }
                    >
                      Premium - $19.99
                    </option>
                    <option
                      value="Professional - $44.99"
                      selected={
                        getSelected() && getSelected() == "Professional"
                          ? true
                          : false
                      }
                    >
                      Professional - $44.99
                    </option>
                    <option
                      value="Enterprise - $82.99"
                      selected={
                        getSelected() && getSelected() == "Enterprise"
                          ? true
                          : false
                      }
                    >
                      Enterprise - $82.99
                    </option>
                  </select>
                </div>
              ) : (
                ""
              )}

              <div className={Styles.demoFormFieldWrapper}>
                <p className={Styles.formLabel}>Country</p>
                <select className={Styles.formSelect} id="country">
                  <option value="">-Please select-</option>
                  <option value="AF">Afghanistan</option>
                  <option value="AX">Aland Islands</option>
                  <option value="AL">Albania</option>
                  <option value="DZ">Algeria</option>
                  <option value="AS">American Samoa</option>
                  <option value="AD">Andorra</option>
                  <option value="AO">Angola</option>
                  <option value="AI">Anguilla</option>
                  <option value="AQ">Antarctica</option>
                  <option value="AG">Antigua and Barbuda</option>
                  <option value="AR">Argentina</option>
                  <option value="AM">Armenia</option>
                  <option value="AW">Aruba</option>
                  <option value="AU">Australia</option>
                  <option value="AT">Austria</option>
                  <option value="AZ">Azerbaijan</option>
                  <option value="BS">Bahamas</option>
                  <option value="BH">Bahrain</option>
                  <option value="BD">Bangladesh</option>
                  <option value="BB">Barbados</option>
                  <option value="BY">Belarus</option>
                  <option value="BE">Belgium</option>
                  <option value="BZ">Belize</option>
                  <option value="BJ">Benin</option>
                  <option value="BM">Bermuda</option>
                  <option value="BT">Bhutan</option>
                  <option value="BO">Bolivia</option>
                  <option value="BA">Bosnia and Herzegovina</option>
                  <option value="BW">Botswana</option>
                  <option value="BV">Bouvet Island</option>
                  <option value="BR">Brazil</option>
                  <option value="IO">British Indian Ocean Territory</option>
                  <option value="BN">Brunei Darussalam</option>
                  <option value="BG">Bulgaria</option>
                  <option value="BF">Burkina Faso</option>
                  <option value="BI">Burundi</option>
                  <option value="KH">Cambodia</option>
                  <option value="CM">Cameroon</option>
                  <option value="CA">Canada</option>
                  <option value="CV">Cape Verde</option>
                  <option value="KY">Cayman Islands</option>
                  <option value="CF">Central African Republic</option>
                  <option value="TD">Chad</option>
                  <option value="CL">Chile</option>
                  <option value="CN">China</option>
                  <option value="CX">Christmas Island</option>
                  <option value="CC">Cocos (Keeling) Islands</option>
                  <option value="CO">Colombia</option>
                  <option value="KM">Comoros</option>
                  <option value="CG">Congo</option>

                  <option value="CK">Cook Islands</option>
                  <option value="CR">Costa Rica</option>
                  <option value="CI">Cote D'Ivoire</option>
                  <option value="HR">Croatia</option>
                  <option value="CU">Cuba</option>
                  <option value="CW">Curacao</option>
                  <option value="CY">Cyprus</option>
                  <option value="CZ">Czech Republic</option>
                  <option value="DK">Denmark</option>
                  <option value="DJ">Djibouti</option>
                  <option value="DM">Dominica</option>
                  <option value="DO">Dominican Republic</option>
                  <option value="EC">Ecuador</option>
                  <option value="EG">Egypt</option>
                  <option value="SV">El Salvador</option>
                  <option value="GQ">Equatorial Guinea</option>
                  <option value="ER">Eritrea</option>
                  <option value="EE">Estonia</option>
                  <option value="ET">Ethiopia</option>
                  <option value="FK">Falkland Islands (Malvinas)</option>
                  <option value="FO">Faroe Islands</option>
                  <option value="FJ">Fiji</option>
                  <option value="FI">Finland</option>
                  <option value="FR">France</option>
                  <option value="GF">French Guiana</option>
                  <option value="PF">French Polynesia</option>
                  <option value="TF">French Southern Territories</option>
                  <option value="GA">Gabon</option>
                  <option value="GM">Gambia</option>
                  <option value="GE">Georgia</option>
                  <option value="DE">Germany</option>
                  <option value="GH">Ghana</option>
                  <option value="GI">Gibraltar</option>
                  <option value="GR">Greece</option>
                  <option value="GL">Greenland</option>
                  <option value="GD">Grenada</option>
                  <option value="GP">Guadeloupe</option>
                  <option value="GU">Guam</option>
                  <option value="GT">Guatemala</option>
                  <option value="GG">Guernsey</option>
                  <option value="GN">Guinea</option>
                  <option value="GW">Guinea-Bissau</option>
                  <option value="GY">Guyana</option>
                  <option value="HT">Haiti</option>
                  <option value="HM">Heard Island and Mcdonald Islands</option>
                  <option value="VA">Holy See (Vatican City State)</option>
                  <option value="HN">Honduras</option>
                  <option value="HK">Hong Kong</option>
                  <option value="HU">Hungary</option>
                  <option value="IS">Iceland</option>
                  <option value="IN">India</option>
                  <option value="ID">Indonesia</option>
                  <option value="IR">Iran</option>
                  <option value="IQ">Iraq</option>
                  <option value="IE">Ireland</option>
                  <option value="IM">Isle of Man</option>
                  <option value="IL">Israel</option>
                  <option value="IT">Italy</option>
                  <option value="JM">Jamaica</option>
                  <option value="JP">Japan</option>
                  <option value="JE">Jersey</option>
                  <option value="JO">Jordan</option>
                  <option value="KZ">Kazakhstan</option>
                  <option value="KE">Kenya</option>
                  <option value="KI">Kiribati</option>

                  <option value="KR">Korea</option>
                  <option value="XK">Kosovo</option>
                  <option value="KW">Kuwait</option>
                  <option value="KG">Kyrgyzstan</option>
                  <option value="LA">Lao People's Democratic Republic</option>
                  <option value="LV">Latvia</option>
                  <option value="LB">Lebanon</option>
                  <option value="LS">Lesotho</option>
                  <option value="LR">Liberia</option>
                  <option value="LY">Libyan Arab Jamahiriya</option>
                  <option value="LI">Liechtenstein</option>
                  <option value="LT">Lithuania</option>
                  <option value="LU">Luxembourg</option>
                  <option value="MO">Macao</option>

                  <option value="MG">Madagascar</option>
                  <option value="MW">Malawi</option>
                  <option value="MY">Malaysia</option>
                  <option value="MV">Maldives</option>
                  <option value="ML">Mali</option>
                  <option value="MT">Malta</option>
                  <option value="MH">Marshall Islands</option>
                  <option value="MQ">Martinique</option>
                  <option value="MR">Mauritania</option>
                  <option value="MU">Mauritius</option>
                  <option value="YT">Mayotte</option>
                  <option value="MX">Mexico</option>
                  <option value="FM">Micronesia</option>
                  <option value="MD">Moldova</option>
                  <option value="MC">Monaco</option>
                  <option value="MN">Mongolia</option>
                  <option value="ME">Montenegro</option>
                  <option value="MS">Montserrat</option>
                  <option value="MA">Morocco</option>
                  <option value="MZ">Mozambique</option>
                  <option value="MM">Myanmar</option>
                  <option value="NA">Namibia</option>
                  <option value="NR">Nauru</option>
                  <option value="NP">Nepal</option>
                  <option value="NL">Netherlands</option>
                  <option value="AN">Netherlands Antilles</option>
                  <option value="NC">New Caledonia</option>
                  <option value="NZ">New Zealand</option>
                  <option value="NI">Nicaragua</option>
                  <option value="NE">Niger</option>
                  <option value="NG">Nigeria</option>
                  <option value="NU">Niue</option>
                  <option value="NF">Norfolk Island</option>
                  <option value="MP">Northern Mariana Islands</option>
                  <option value="NO">Norway</option>
                  <option value="OM">Oman</option>
                  <option value="PK">Pakistan</option>
                  <option value="PW">Palau</option>
                  <option value="PS">Palestinian Territory, Occupied</option>
                  <option value="PA">Panama</option>
                  <option value="PG">Papua New Guinea</option>
                  <option value="PY">Paraguay</option>
                  <option value="PE">Peru</option>
                  <option value="PH">Philippines</option>
                  <option value="PN">Pitcairn</option>
                  <option value="PL">Poland</option>
                  <option value="PT">Portugal</option>
                  <option value="PR">Puerto Rico</option>
                  <option value="QA">Qatar</option>
                  <option value="RE">Reunion</option>
                  <option value="RO">Romania</option>
                  <option value="RU">Russian Federation</option>
                  <option value="RW">Rwanda</option>
                  <option value="BL">Saint Barthelemy</option>
                  <option value="SH">Saint Helena</option>
                  <option value="KN">Saint Kitts and Nevis</option>
                  <option value="LC">Saint Lucia</option>
                  <option value="MF">Saint Martin</option>
                  <option value="PM">Saint Pierre and Miquelon</option>
                  <option value="VC">Saint Vincent and the Grenadines</option>
                  <option value="WS">Samoa</option>
                  <option value="SM">San Marino</option>
                  <option value="ST">Sao Tome and Principe</option>
                  <option value="SA">Saudi Arabia</option>
                  <option value="SN">Senegal</option>
                  <option value="RS">Serbia</option>
                  <option value="CS">Serbia and Montenegro</option>
                  <option value="SC">Seychelles</option>
                  <option value="SL">Sierra Leone</option>
                  <option value="SG">Singapore</option>
                  <option value="SX">Sint Maarten</option>
                  <option value="SK">Slovakia</option>
                  <option value="SI">Slovenia</option>
                  <option value="SB">Solomon Islands</option>
                  <option value="SO">Somalia</option>
                  <option value="ZA">South Africa</option>
                  <option value="GS">
                    South Georgia and the South Sandwich Islands
                  </option>
                  <option value="SS">South Sudan</option>
                  <option value="ES">Spain</option>
                  <option value="LK">Sri Lanka</option>
                  <option value="SD">Sudan</option>
                  <option value="SR">Suriname</option>
                  <option value="SJ">Svalbard and Jan Mayen</option>
                  <option value="SZ">Swaziland</option>
                  <option value="SE">Sweden</option>
                  <option value="CH">Switzerland</option>
                  <option value="SY">Syrian Arab Republic</option>
                  <option value="TW">Taiwan, Province of China</option>
                  <option value="TJ">Tajikistan</option>

                  <option value="TH">Thailand</option>
                  <option value="TL">Timor-Leste</option>
                  <option value="TG">Togo</option>
                  <option value="TK">Tokelau</option>
                  <option value="TO">Tonga</option>
                  <option value="TT">Trinidad and Tobago</option>
                  <option value="TN">Tunisia</option>
                  <option value="TR">Turkey</option>
                  <option value="TM">Turkmenistan</option>
                  <option value="TC">Turks and Caicos Islands</option>
                  <option value="TV">Tuvalu</option>
                  <option value="UG">Uganda</option>
                  <option value="UA">Ukraine</option>
                  <option value="AE">United Arab Emirates</option>
                  <option value="GB">United Kingdom</option>
                  <option value="US">United States</option>
                  <option value="UM">
                    United States Minor Outlying Islands
                  </option>
                  <option value="UY">Uruguay</option>
                  <option value="UZ">Uzbekistan</option>
                  <option value="VU">Vanuatu</option>
                  <option value="VE">Venezuela</option>
                  <option value="VN">Viet Nam</option>
                  <option value="VG">Virgin Islands, British</option>
                  <option value="VI">Virgin Islands, U.s.</option>
                  <option value="WF">Wallis and Futuna</option>
                  <option value="EH">Western Sahara</option>
                  <option value="YE">Yemen</option>
                  <option value="ZM">Zambia</option>
                  <option value="ZW">Zimbabwe</option>
                </select>
                <p className={Styles.requiredField}>*Required</p>
              </div>
              <div className={Styles.demoFormFieldWrapper}>
                <p className={Styles.formLabel}>Company Annual Revenue</p>
                <select className={Styles.formSelect} id="annualRevenue">
                  <option value="">-Please select-</option>
                  <option value="<1M"> {"<1M"} </option>
                  <option value="1M-5M"> 1M-5M </option>
                  <option value="5M+"> 5M+ </option>
                </select>
                <p className={Styles.requiredField}>*Required</p>
              </div>
            </div>

            <form
              id="dataForm"
              className={Styles.dataForm}
              action="https://www.paypal.com/cgi-bin/webscr"
              method="post"
            >
              <input
                type="hidden"
                name="business"
                value="support@kewlclothing.com"
              />
              <input type="hidden" name="cmd" value="_xclick" />
              <input
                type="hidden"
                name="item_name"
                value={selectedPlan ? selectedPlan.split("-")[0].trim() : ""}
              />
              <input
                type="hidden"
                name="amount"
                value={
                  selectedPlan
                    ? selectedPlan.split("-")[1].trim().replace("$", "")
                    : ""
                }
              />
              <input type="hidden" name="currency_code" value="USD" />
              <input
                type="image"
                border="0"
                className={Styles.buyButton}
                onClick={handleFormSubmit}
                src="https://www.paypalobjects.com/en_US/i/btn/btn_buynow_LG.gif"
                alt="Buy Now"
              />{" "}
              <img
                alt=""
                border="0"
                width="1"
                height="1"
                src="https://www.paypalobjects.com/en_US/i/scr/pixel.gif"
              />
            </form>
            {/* <div className={Styles.submitButton}>
              <p className={Styles.submitButtonText}>Schedule a Demo</p>
            </div> */}
          </div>
        ) : (
          <div className={Styles.formSuccessUi}>
            <img
              src={formSuccessIcon}
              alt="img"
              className={Styles.formSuccessIcon}
            />
            <p className={Styles.successTextHead}>
              Thank you for showing interest in{" "}
              <span className={Styles.gradientText}>KewlHosting!</span>
            </p>
            <p className={Styles.successText}>
              Our Team will reach out to you shortly. Have a great day!
            </p>
          </div>
        )}
      </div>
    </div>
  );
}

export default RequestPopup;
