import React, { Fragment } from "react";
import Section1 from "../Section1/Section1";
import Section2 from "../Section2/Section2";
import Section3 from "../Section3/Section3";
import Section4 from "../Section4/Section4";
import Section5 from "../Section5/Section5";
function Home(props) {
  return (
    <Fragment>
      <Section1 changeModalStatus={props.changeModalStatus} />
      <Section2 />
      <Section3 />
      <Section4 changeModalStatus={props.changeModalStatus} />
      <Section5 changeModalStatus={props.changeModalStatus} />
    </Fragment>
  );
}

export default Home;
