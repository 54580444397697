import React, { useEffect } from "react";
import Styles from "./MobileMenuPopup.module.css";
import Logo from "../../assets/logo.svg";
import closeIconWhite from "../../assets/closeIcon_white.svg";
import classnames from "classnames";
function MobileMenuPopup(props) {
  useEffect(() => {
    function handleClick(e) {
      let classnamesStr = e.target.getAttribute("class");
      if (classnamesStr && classnamesStr.search("containerWrapper") > -1) {
        props.changeStatus(false);
      }
    }
    document.addEventListener("click", handleClick);
    return () => {
      document.removeEventListener("click", handleClick);
    };
  });

  function handleBookButton() {
    props.changeStatus(false);
    props.changeModalStatus((oldState) => {
      let temp = { ...oldState };
      temp.status = true;
      return temp;
    });
  }
  return (
    <div
      className={classnames(
        Styles.containerWrapper,
        props.mobileMenuModalStatus ? Styles.containerWrapperActive : ""
      )}
    >
      <div className={Styles.container}>
        <div className={Styles.header}>
          <img src={Logo} className={Styles.logo} alt="logo" />
          <img
            src={closeIconWhite}
            className={Styles.closeIcon}
            alt="closeIcon"
            onClick={() => {
              props.changeStatus(false);
            }}
          />
        </div>
        <div className={Styles.body}>
          <p
            className={Styles.navItem}
            onClick={() => {
              document.location = "#pricing";
              props.changeStatus(false);
            }}
          >
            Pricing
          </p>
          <div className={Styles.bookButton} onClick={handleBookButton}>
            <p className={Styles.buttonText} onClick={handleBookButton}>
              Book a Demo
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MobileMenuPopup;
