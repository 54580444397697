import React from "react";
import Styles from "./Section1.module.css";
import bg_1 from "../../assets/bg_1.png";
import phone_icon from "../../assets/phone_icon.svg";
import section1Img from "../../assets/section1_img.png";
import bg_1_mobile from "../../assets/bg_1_mobile.svg";
import section1Img_2 from "../../assets/section1_img_2.svg";
import hangerIcon from "../../assets/hanger_icon.svg";
import classnames from "classnames";
function Section1(props) {
  function handlePopup() {
    props.changeModalStatus((oldState) => {
      let temp = { ...oldState };
      temp.status = true;

      return temp;
    });
  }
  return (
    <div className={Styles.container}>
      <img src={bg_1} alt="bg1" className={Styles.bg_img} />
      <img src={bg_1_mobile} alt="bg1" className={Styles.bg_img_mobile} />
      <div className={Styles.mainBox}>
        <div className={Styles.containerTextWrapper}>
          <div className={Styles.containerTextOnly}>
            <p className={Styles.containerText}>Set up your online</p>
            <div className={Styles.containerTextSpan}>
              <img
                src={hangerIcon}
                className={Styles.header_icon}
                alt="headericon"
              />
              <p
                className={classnames(
                  Styles.containerText,
                  Styles.containerTextBold
                )}
              >
                clothing store
              </p>
            </div>
            <p className={Styles.containerText}>with us </p>
          </div>

          <div className={Styles.bookButton} onClick={handlePopup}>
            <img
              src={phone_icon}
              alt="phnIcon"
              className={Styles.phoneIcon}
              onClick={handlePopup}
            />
            <p onClick={handlePopup}>Schedule a Demo</p>
          </div>
        </div>
        <div className={Styles.containerImg}>
          <img src={section1Img_2} alt="img" className={Styles.sectionImg} />
        </div>
      </div>
    </div>
  );
}

export default Section1;
