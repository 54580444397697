import React from "react";
import Styles from "./Others.module.css";
function TOS(props) {
  return (
    <div className={Styles.containerWrapper} id="privacy">
      <div className={Styles.container}>
        {" "}
        <h1 className={Styles.heading1}>Privacy Policy for KewlHosting</h1>
        <p className={Styles.normalText}>
          At KewlHosting, accessible from https://kewlhosting.net, one of our
          main priorities is the privacy of our visitors. This Privacy Policy
          document contains types of information that is collected and recorded
          by KewlHosting and how we use it.
        </p>
        <p className={Styles.normalText}>
          If you have additional questions or require more information about our
          Privacy Policy, do not hesitate to contact us through email at
          dev@kewlhosting.net
        </p>
        <div className={Styles.contentBox}>
          <h2 className={Styles.heading2}>
            General Data Protection Regulation (GDPR)
          </h2>
          <p className={Styles.normalText}>
            We are a Data Controller of your information.
          </p>
          <p className={Styles.normalText}>
            KewlHosting legal basis for collecting and using the personal
            information described in this Privacy Policy depends on the Personal
            Information we collect and the specific context in which we collect
            the information:
          </p>
          <ul>
            <li>
              <p className={Styles.normalText}>
                KewlHosting needs to perform a contract with you
              </p>
            </li>
            <li>
              <p className={Styles.normalText}>
                You have given KewlHosting permission to do so
              </p>
            </li>
            <li>
              <p className={Styles.normalText}>
                Processing your personal information is in KewlHosting
                legitimate interests
              </p>
            </li>
            <li>
              <p className={Styles.normalText}>
                KewlHosting needs to comply with the law
              </p>
            </li>
          </ul>
          <p className={Styles.normalText}>
            KewlHosting will retain your personal information only for as long
            as is necessary for the purposes set out in this Privacy Policy. We
            will retain and use your information to the extent necessary to
            comply with our legal obligations, resolve disputes, and enforce our
            policies.
          </p>
          <p className={Styles.normalText}>
            If you are a resident of the European Economic Area (EEA), you have
            certain data protection rights. If you wish to be informed what
            Personal Information we hold about you and if you want it to be
            removed from our systems, please contact us.
          </p>
          <p className={Styles.normalText}>
            In certain circumstances, you have the following data protection
            rights:
          </p>
          <ul>
            <li>
              <p className={Styles.normalText}>
                The right to access, update or to delete the information we have
                on you.
              </p>
            </li>
            <li>
              <p className={Styles.normalText}>The right of rectification.</p>
            </li>
            <li>
              <p className={Styles.normalText}>The right to object.</p>
            </li>
            <li>
              <p className={Styles.normalText}>The right of restriction.</p>
            </li>
            <li>
              <p className={Styles.normalText}>The right to data portability</p>
            </li>
            <li>
              <p className={Styles.normalText}>The right to withdraw consent</p>
            </li>
          </ul>
        </div>
        <div className={Styles.contentBox}>
          <h2 className={Styles.heading2}>Log Files</h2>
          <p className={Styles.normalText}>
            KewlHosting follows a standard procedure of using log files. These
            files log visitors when they visit websites. All hosting companies
            do this and a part of hosting services' analytics. The information
            collected by log files include internet protocol (IP) addresses,
            browser type, Internet Service Provider (ISP), date and time stamp,
            referring/exit pages, and possibly the number of clicks. These are
            not linked to any information that is personally identifiable. The
            purpose of the information is for analyzing trends, administering
            the site, tracking users' movement on the website, and gathering
            demographic information.
          </p>
        </div>
        <div className={Styles.contentBox}>
          <h2 className={Styles.heading2}>Cookies and Web Beacons</h2>
          <p className={Styles.normalText}>
            Like any other website, KewlHosting uses 'cookies'. These cookies
            are used to store information including visitors' preferences, and
            the pages on the website that the visitor accessed or visited. The
            information is used to optimize the users' experience by customizing
            our web page content based on visitors' browser type and/or other
            information.
          </p>
        </div>
        <div className={Styles.contentBox}>
          <h2 className={Styles.heading2}>Privacy Policies</h2>
          <p className={Styles.normalText}>
            You may consult this list to find the Privacy Policy for each of the
            advertising partners of KewlHosting.
          </p>
          <p className={Styles.normalText}>
            Third-party ad servers or ad networks uses technologies like
            cookies, JavaScript, or Web Beacons that are used in their
            respective advertisements and links that appear on KewlHosting,
            which are sent directly to users' browser. They automatically
            receive your IP address when this occurs. These technologies are
            used to measure the effectiveness of their advertising campaigns
            and/or to personalize the advertising content that you see on
            websites that you visit.
          </p>
          <p className={Styles.normalText}>
            Note that KewlHosting has no access to or control over these cookies
            that are used by third-party advertisers.
          </p>
        </div>
        <div className={Styles.contentBox}>
          <h2 className={Styles.heading2}>Third Party Privacy Policies</h2>
          <p className={Styles.normalText}>
            KewlHosting's Privacy Policy does not apply to other advertisers or
            websites. Thus, we are advising you to consult the respective
            Privacy Policies of these third-party ad servers for more detailed
            information. It may include their practices and instructions about
            how to opt-out of certain options. You may find a complete list of
            these Privacy Policies and their links here: Privacy Policy Links.
          </p>
          <p className={Styles.normalText}>
            You can choose to disable cookies through your individual browser
            options. To know more detailed information about cookie management
            with specific web browsers, it can be found at the browsers'
            respective websites. What Are Cookies?
          </p>
        </div>
        <div className={Styles.contentBox}>
          <h2 className={Styles.heading2}>Children's Information</h2>
          <p className={Styles.normalText}>
            Another part of our priority is adding protection for children while
            using the internet. We encourage parents and guardians to observe,
            participate in, and/or monitor and guide their online activity.
          </p>
          <p className={Styles.normalText}>
            KewlHosting does not knowingly collect any Personal Identifiable
            Information from children under the age of 13. If you think that
            your child provided this kind of information on our website, we
            strongly encourage you to contact us immediately and we will do our
            best efforts to promptly remove such information from our records.
          </p>
        </div>
        <div className={Styles.contentBox}>
          <h2 className={Styles.heading2}>Online Privacy Policy Only</h2>
          <p className={Styles.normalText}>
            This privacy policy applies only to our online activities and is
            valid for visitors to our website with regards to the information
            that they shared and/or collect in KewlHosting. This policy is not
            applicable to any information collected offline or via channels
            other than this website.
          </p>
        </div>
        <div className={Styles.contentBox}>
          <h2 className={Styles.heading2}>Consent</h2>
          <p className={Styles.normalText}>
            By using our website, you hereby consent to our Privacy Policy and
            agree to its terms.
          </p>
        </div>
      </div>
    </div>
  );
}

export default TOS;
