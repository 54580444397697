import React from "react";
import Styles from "./Section4.module.css";
import SubscriptionPlanBox from "./SubscriptionPlanBox";

function Section4(props) {
  return (
    <div className={Styles.container} id="pricing">
      <div className={Styles.headingWrapper}>
        <p className={Styles.heading}>our subscription plans</p>
        <p className={Styles.subHeading}>
          Just pick the one that suits you. You can switch it anytime.
        </p>
      </div>
      <div className={Styles.body}>
        <SubscriptionPlanBox changeModalStatus={props.changeModalStatus} />
      </div>
    </div>
  );
}

export default Section4;
