import React from "react";
import Styles from "./Section2.module.css";
import step1Img from "../../assets/step1_img.svg";
import step2Img from "../../assets/step2_img.svg";
import step3Img from "../../assets/step3_img.svg";
import classnames from "classnames";
import ellipse_1 from "../../assets/ellipse_1.svg";
function Section2(props) {
  return (
    <div className={Styles.container}>
      <div className={Styles.bgElement}></div>
      <div className={Styles.containerHeadingWrapper}>
        <p className={Styles.headingText}>GET LIVE IN JUST 3 STEPS!</p>

        <p className={Styles.subHeadingText}>
          We promise, it will not take more than 10 minutes.
        </p>
      </div>
      <div className={Styles.containerBody}>
        <div className={Styles.stepWrapper}>
          <img src={step1Img} alt="step1" className={Styles.stepImage} />
          <div className={Styles.stepTextWrapper}>
            <p className={Styles.stepNumber}>1</p>
            <div className={Styles.stepTextContainer}>
              <img src={ellipse_1} className={Styles.ellipse} />
              <p className={Styles.stepHeading}>choose your website layout</p>
              <p className={Styles.stepText}>
                Choose your design from the wide collection of layout with
                themes, and get ready to list your brand prodcuts.
              </p>
            </div>
          </div>
        </div>
        <div className={classnames(Styles.stepWrapper, Styles.stepWrapperRev)}>
          <img src={step2Img} alt="step1" className={Styles.stepImage} />
          <div className={Styles.stepTextWrapper}>
            <p className={Styles.stepNumber}>2</p>
            <div className={Styles.stepTextContainer}>
              <img src={ellipse_1} className={Styles.ellipse} />
              <p className={Styles.stepHeading}>Add your products</p>
              <p className={Styles.stepText}>
                Upload all your products with description, images, pricing,
                colours, sizes, quantity and more.
              </p>
            </div>
          </div>
        </div>
        <div className={Styles.stepWrapper}>
          <img src={step3Img} alt="step1" className={Styles.stepImage} />
          <div className={Styles.stepTextWrapper}>
            <p className={Styles.stepNumber}>3</p>
            <div className={Styles.stepTextContainer}>
              <img src={ellipse_1} className={Styles.ellipse} />
              <p className={Styles.stepHeading}>ready to go live</p>
              <p className={Styles.stepText}>
                Choose your domain and just hit your last click to publish your
                brand.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Section2;
