import React from "react";
import Styles from "./Section5.module.css";
import img from "../../assets/section5Img_2.svg";
import { useMediaQuery } from "react-responsive";

function Section5(props) {
  const isTabView = useMediaQuery({
    query: "(max-width:768px) and (min-width:480px)",
  });
  const isBigScreen = useMediaQuery({
    query: "(min-width:768.5px)",
  });

  function handleSchedule() {
    let userInput = document.getElementById("userInput").value;
    if (userInput) {
      props.changeModalStatus((oldState) => {
        let temp = { ...oldState };
        temp.status = true;
        temp.data = {
          userEmail: userInput,
        };
        return temp;
      });
    }
  }
  return (
    <div className={Styles.container}>
      {/* {isTabView ? (
        <img src={img} className={Styles.containerImg} alt="img" />
      ) : (
        ""
      )} */}
      <div className={Styles.containerContent}>
        {/* {isBigScreen ? (
          <img src={img} className={Styles.containerImg} alt="img" />
        ) : (
          ""
        )} */}
        <p className={Styles.containerText}>Schedule a demo with us</p>
        <div className={Styles.inputWrapper}>
          <input
            type="text"
            className={Styles.userInput}
            id="userInput"
            placeholder="Enter your email address"
          />
          {/* <div className={Styles.scheduleButton}> */}
          <p className={Styles.buttonText} onClick={handleSchedule}>
            Schedule
          </p>
          {/* </div> */}
        </div>
      </div>
    </div>
  );
}

export default Section5;
