import React, { Fragment, useState } from "react";
import Styles from "./Section4.module.css";
import checkmark from "../../assets/checkMark.svg";
import whiteCheckmark from "../../assets/white_arrow.svg";
import classnames from "classnames";
import Slider from "react-slick";
import { useMediaQuery } from "react-responsive";

function SubscriptionPlanBox(props) {
  const isMobile = useMediaQuery({
    query: "(max-width:480px)",
  });
  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 3,
    arrows: false,
  };
  let [hoverState, changeHoverState] = useState({
    current: -1,
    status: false,
  });

  let [buttonHoverState, changeButtonHoverState] = useState({
    current: -1,
    status: false,
  });
  let planArr = [
    {
      type: "Basic",
      price: 7.5,
      features: [
        "Online Store",
        "Hosted on our Sub-Domain",
        "Unlimited Products",
        "Free SSL Certificate",
        "2 Staff accounts",
        "24/7 Support",
      ],
    },
    {
      type: "Premium",
      price: 19.99,
      features: [
        "Online Store",
        "Custom Domain support",
        "Unlimited Products",
        "Free SSL Certificate",
        "24/7 Support",
        "4 Staff accounts",
        "Gift Cards",
      ],
    },
    {
      type: "Professional",
      price: 44.99,
      features: [
        "Online Store",
        "Custom Domain support",
        "Unlimited Products",
        "Free SSL Certificate",
        "24/7 Priority* Support",
        "6 Staff accounts",
        "Gift Cards",
      ],
    },
    {
      type: "Enterprise",
      price: 82.99,
      features: [
        "Online Store",
        "Custom Domain support",
        "Unlimited Products",
        "Free SSL Certificate",
        "24/7 Priority* Support",
        "8 Staff accounts",
        "Affiliate Access",
        "Unlimited Discount coupons",
      ],
    },
  ];

  function mouseEnterHandler(index) {
    changeHoverState((oldState) => {
      let temp = { ...oldState };
      temp.current = index;
      temp.status = true;
      return temp;
    });
  }

  function mouseLeaveHandler(index) {
    changeHoverState((oldState) => {
      let temp = { ...oldState };
      temp.current = index;
      temp.status = false;
      return temp;
    });
  }

  function mouseEnterButton(index) {
    changeButtonHoverState((oldState) => {
      let temp = { ...oldState };
      temp.current = index;
      temp.status = true;
      return temp;
    });
  }

  function mouseLeaveButton(index) {
    changeButtonHoverState((oldState) => {
      let temp = { ...oldState };
      temp.current = index;
      temp.status = false;
      return temp;
    });
  }

  function isCurrentBoxActive(index) {
    return hoverState.current == index && hoverState.status;
  }

  function uiProcessor() {
    return planArr.map((val, index) => {
      return (
        <div
          className={classnames(
            Styles.subscriptionBox,
            isCurrentBoxActive(index) ? Styles.subscriptionBoxActive : ""
          )}
          // onMouseEnter={() => {
          //   mouseEnterHandler(index);
          // }}
          // onMouseLeave={() => {
          //   mouseLeaveHandler(index);
          // }}

          onMouseEnter={() => {
            mouseEnterButton(index);
          }}
          onMouseLeave={() => {
            mouseLeaveButton(index);
          }}
          onClick={() => {
            props.changeModalStatus((oldState) => {
              let temp = { ...oldState };
              temp.status = true;
              temp.data = { plan: val.type };
              return temp;
            });
          }}
        >
          <p
            className={classnames(
              Styles.subscriptionHeading,
              isCurrentBoxActive(index) ? Styles.subscriptionHeadingActive : ""
            )}
          >
            {val.type}
          </p>
          <p
            className={classnames(
              Styles.subscriptionPrice,
              isCurrentBoxActive(index) ? Styles.subscriptionPriceActive : ""
            )}
          >
            ${val.price}
          </p>
          <div className={Styles.subscriptionFeatures}>
            {val.features.map((feature, featureIndex) => {
              return (
                <div className={Styles.subscriptionFeature}>
                  <img
                    src={isCurrentBoxActive(index) ? whiteCheckmark : checkmark}
                    alt="img"
                    className={Styles.checkMark}
                  />
                  <p
                    className={classnames(
                      Styles.subscriptionFeatureText,
                      isCurrentBoxActive(index)
                        ? Styles.subscriptionFeatureTextActive
                        : ""
                    )}
                  >
                    {feature}
                  </p>
                </div>
              );
            })}
          </div>
          <div
            className={classnames(
              Styles.getStarted,
              buttonHoverState.status && buttonHoverState.current == index
                ? Styles.getStartedActive
                : ""
            )}
            onMouseEnter={() => {
              mouseEnterButton(index);
            }}
            onMouseLeave={() => {
              mouseLeaveButton(index);
            }}
            // onClick={() => {
            //   props.changeModalStatus(true);
            // }}
          >
            <p
              className={classnames(
                Styles.getStartedText,
                buttonHoverState.status && buttonHoverState.current == index
                  ? Styles.getStartedTextActive
                  : ""
              )}
            >
              get started
            </p>
          </div>
        </div>
      );
    });
  }
  return isMobile ? (
    <Slider {...settings}>{uiProcessor()}</Slider>
  ) : (
    <Fragment>{uiProcessor()}</Fragment>
  );
}

export default SubscriptionPlanBox;
