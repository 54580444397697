import React from "react";
import Styles from "./Section3.module.css";
import featureImg1 from "../../assets/feature_img_1.svg";
import featureImg2 from "../../assets/feature_img_2.svg";
import featureImg3 from "../../assets/feature_img_3.svg";
import featureImg4 from "../../assets/feature_img_4.svg";

function Section3(props) {
  return (
    <div className={Styles.container}>
      <div className={Styles.body}>
        <div className={Styles.elementWrapper}>
          <img src={featureImg1} alt="img" className={Styles.featureImg} />
          <p className={Styles.featureText}>Easy to Setup</p>
        </div>
        <div className={Styles.elementWrapper}>
          <img src={featureImg2} alt="img" className={Styles.featureImg} />
          <p className={Styles.featureText}>Analytics Reports</p>
        </div>
        <div className={Styles.elementWrapper}>
          <img src={featureImg4} alt="img" className={Styles.featureImg} />
          <p className={Styles.featureText}>24x7 Support</p>
        </div>
        <div className={Styles.elementWrapper}>
          <img src={featureImg3} alt="img" className={Styles.featureImg} />
          <p className={Styles.featureText}>Data Security</p>
        </div>
      </div>
    </div>
  );
}

export default Section3;
