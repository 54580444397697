import React, { useState, Fragment } from "react";
import Styles from "./Header.module.css";
import Logo from "../../assets/logo.svg";
import mobileMenuIcon from "../../assets/mobileMenuIcon.svg";
import MobileMenuPopup from "../Modal/MobileMenuPopup";
import { useNavigate, useLocation } from "react-router-dom";

function Header(props) {
  let navigate = useNavigate();
  let location = useLocation();
  let [mobileMenuModalStatus, changeStatus] = useState(false);
  function handlePopup() {
    props.changeModalStatus((oldState) => {
      let temp = { ...oldState };
      temp.status = true;
      return temp;
    });
  }
  function isHome() {
    return (
      location.pathname &&
      location.pathname.toLowerCase().search("terms") == -1 &&
      location.pathname.toLowerCase().search("privacy") == -1
    );
  }

  function headerUi() {
    return (
      <Fragment>
        {" "}
        <img
          src={Logo}
          className={Styles.logo}
          alt="logo"
          onClick={() => {
            navigate("/");
          }}
        />
        <div className={Styles.mobileMenuWrapper}>
          <img
            src={mobileMenuIcon}
            className={Styles.mobileMenuIcon}
            alt="img"
            onClick={() => {
              changeStatus(true);
            }}
          />
          <div className={Styles.mobileMenu}>
            <MobileMenuPopup
              mobileMenuModalStatus={mobileMenuModalStatus}
              changeStatus={changeStatus}
              changeModalStatus={props.changeModalStatus}
            />
          </div>
        </div>
        <div className={Styles.navItems}>
          <div className={Styles.navItemWrapper}>
            <p
              className={Styles.navItem}
              onClick={() => {
                document.location = "/#pricing";
              }}
            >
              Pricing
            </p>
            <div className={Styles.navItemUnderline}></div>
          </div>

          <div className={Styles.bookButton} onClick={handlePopup}>
            <p onClick={handlePopup}>Book a Demo</p>
          </div>
        </div>
      </Fragment>
    );
  }
  return (
    <div className={isHome() ? Styles.container : Styles.containerOtherPage}>
      {isHome() ? (
        headerUi()
      ) : (
        <div className={Styles.containerMain}>{headerUi()}</div>
      )}
    </div>
  );
}

export default Header;
