import React from "react";
import Styles from "./Others.module.css";
function TOS(props) {
  return (
    <div className={Styles.containerWrapper} id="tos">
      <div className={Styles.container}>
        {" "}
        <h1 className={Styles.heading1}>
          Terms and Conditions for KewlHosting
        </h1>
        <div className={Styles.contentBox}>
          <h2 className={Styles.heading2}>Introduction</h2>
          <p className={Styles.normalText}>
            These Website Standard Terms and Conditions written on this webpage
            shall manage your use of our website, KewlHosting accessible at
            https://kewlhosting.net.
          </p>
          <p className={Styles.normalText}>
            These Terms will be applied fully and affect to your use of this
            Website. By using this Website, you agreed to accept all terms and
            conditions written in here. You must not use this Website if you
            disagree with any of these Website Standard Terms and Conditions.
          </p>
          <p className={Styles.normalText}>
            Minors or people below 18 years old are not allowed to use this
            Website.
          </p>
        </div>
        <div className={Styles.contentBox}>
          <h2 className={Styles.heading2}>Restrictions</h2>
          <p className={Styles.normalText}>
            You are specifically restricted from all of the following:
          </p>
          <ul>
            <li>
              <p className={Styles.normalText}>
                publishing any Website material in any other media;
              </p>
            </li>
            <li>
              <p className={Styles.normalText}>
                selling, sublicensing and/or otherwise commercializing any
                Website material;
              </p>
            </li>
            <li>
              <p className={Styles.normalText}>
                publicly performing and/or showing any Website material;
              </p>
            </li>
            <li>
              <p className={Styles.normalText}>
                using this Website in any way that is or may be damaging to this
                Website;
              </p>
            </li>
            <li>
              <p className={Styles.normalText}>
                using this Website in any way that impacts user access to this
                Website;
              </p>
            </li>
            <li>
              <p className={Styles.normalText}>
                using this Website contrary to applicable laws and regulations,
                or in any way may cause harm to the Website, or to any person or
                business entity;
              </p>
            </li>
            <li>
              <p className={Styles.normalText}>
                engaging in any data mining, data harvesting, data extracting or
                any other similar activity in relation to this Website;
              </p>
            </li>
            <li>
              <p className={Styles.normalText}>
                using this Website to engage in any advertising or marketing.
              </p>
            </li>
          </ul>
          <p className={Styles.normalText}>
            Certain areas of this Website are restricted from being access by
            you and KewlHosting may further restrict access by you to any areas
            of this Website, at any time, in absolute discretion. Any user ID
            and password you may have for this Website are confidential and you
            must maintain confidentiality as well.
          </p>
        </div>
        <div className={Styles.contentBox}>
          <h2 className={Styles.heading2}>Your Content</h2>
          <p className={Styles.normalText}>
            In these Website Standard Terms and Conditions, "Your Content" shall
            mean any audio, video text, images or other material you choose to
            display on this Website. By displaying Your Content, you grant
            KewlHosting a non-exclusive, worldwide irrevocable, sub licensable
            license to use, reproduce, adapt, publish, translate and distribute
            it in any and all media.
          </p>
          <p className={Styles.normalText}>
            Your Content must be your own and must not be invading any
            third-party’s rights. KewlHosting reserves the right to remove any
            of Your Content from this Website at any time without notice.
          </p>
        </div>
        <div className={Styles.contentBox}>
          <h2 className={Styles.heading2}>No warranties</h2>
          <p className={Styles.normalText}>
            This Website is provided "as is," with all faults, and KewlHosting
            express no representations or warranties, of any kind related to
            this Website or the materials contained on this Website. Also,
            nothing contained on this Website shall be interpreted as advising
            you.
          </p>
        </div>
        <div className={Styles.contentBox}>
          <h2 className={Styles.heading2}>Limitation of liability</h2>
          <p className={Styles.normalText}>
            In no event shall KewlHosting, nor any of its officers, directors
            and employees, shall be held liable for anything arising out of or
            in any way connected with your use of this Website whether such
            liability is under contract. KewlHosting, including its officers,
            directors and employees shall not be held liable for any indirect,
            consequential or special liability arising out of or in any way
            related to your use of this Website.
          </p>
        </div>
        <div className={Styles.contentBox}>
          <h2 className={Styles.heading2}>Indemnification</h2>
          <p className={Styles.normalText}>
            You hereby indemnify to the fullest extent KewlHosting from and
            against any and/or all liabilities, costs, demands, causes of
            action, damages and expenses arising in any way related to your
            breach of any of the provisions of these Terms.
          </p>
        </div>
        <div className={Styles.contentBox}>
          <h2 className={Styles.heading2}>Severability</h2>
          <p className={Styles.normalText}>
            If any provision of these Terms is found to be invalid under any
            applicable law, such provisions shall be deleted without affecting
            the remaining provisions herein.
          </p>
        </div>
        <div className={Styles.contentBox}>
          <h2 className={Styles.heading2}>Variation of Terms</h2>
          <p className={Styles.normalText}>
            KewlHosting is permitted to revise these Terms at any time as it
            sees fit, and by using this Website you are expected to review these
            Terms on a regular basis.
          </p>
        </div>
        <div className={Styles.contentBox}>
          <h2 className={Styles.heading2}>Assignment</h2>
          <p className={Styles.normalText}>
            The KewlHosting is allowed to assign, transfer, and subcontract its
            rights and/or obligations under these Terms without any
            notification. However, you are not allowed to assign, transfer, or
            subcontract any of your rights and/or obligations under these Terms.
          </p>
        </div>{" "}
        <div className={Styles.contentBox}>
          <h2 className={Styles.heading2}>Entire Agreement</h2>
          <p className={Styles.normalText}>
            These Terms constitute the entire agreement between KewlHosting and
            you in relation to your use of this Website, and supersede all prior
            agreements and understandings.
          </p>
        </div>
        <div className={Styles.contentBox}>
          <h2 className={Styles.heading2}>Governing Law & Jurisdiction</h2>
          <p className={Styles.normalText}>
            These Terms will be governed by and interpreted in accordance with
            the laws of the U.S., and you submit to the non-exclusive
            jurisdiction of the state and federal courts located in U.S. for the
            resolution of any disputes.
          </p>
        </div>
      </div>
    </div>
  );
}

export default TOS;
