import React from "react";
import Styles from "./Section6.module.css";
import logo from "../../assets/logo2.svg";
import instaLogo from "../../assets/instaLogo.svg";

import { useNavigate } from "react-router-dom";
function Section6(props) {
  let navigate = useNavigate();
  return (
    <div className={Styles.container}>
      <div className={Styles.logoWrapper}>
        <img
          src={logo}
          alt="logo"
          className={Styles.logo}
          onClick={() => {
            navigate("/");
          }}
        />
        <img
          src={instaLogo}
          alt="logo"
          className={Styles.instaLogo}
          onClick={() => {
            window.open("https://www.instagram.com/kewlclothings/", "_blank");
          }}
        />
      </div>
      <div className={Styles.linksWrapper}>
        <p
          className={Styles.links}
          onClick={() => {
            navigate("/terms-of-service");
          }}
        >
          Terms of Service
        </p>
        <p
          className={Styles.links}
          onClick={() => {
            navigate("/privacy-policy");
          }}
        >
          Privacy Policy
        </p>
      </div>
      <p className={Styles.rightsText}>
        © 2022 All rights reserved. Kewl Clothing LLC{" "}
      </p>
    </div>
  );
}

export default Section6;
